import { GetStaticProps } from 'next';
import { getSanityClient } from 'jexity/packages/feature/common';
import { composeNavigationLinks } from 'jexity/packages/utils/navigationHelpers';
import { DEFAULT_LANGUAGE } from '../theme/languages';
import { ContentPage, ContentPageProps } from '../feature/page/ContentPage';
import { loadSiteSettings } from '../feature/common/siteSettingsLoader';
import { HomePageDocument, queryAllHomePageQuery } from '../feature/page/content/home-page/home';
import { TRANSLATIONS_EN } from '../theme/translations/en';
import { TRANSLATIONS_DE } from '../theme/translations/de';

export default ContentPage;

export const getStaticProps: GetStaticProps<ContentPageProps> = async (props) => {
  const { locale = DEFAULT_LANGUAGE, preview, previewData } = props;

  const { siteSettings, companyInfo } = await loadSiteSettings(locale);
  const sanityClient = getSanityClient();
  const outputPage = await sanityClient.fetch<HomePageDocument>(queryAllHomePageQuery, { locale });
  const translations = locale === 'de' ? TRANSLATIONS_DE : TRANSLATIONS_EN;

  return {
    props: {
      page: outputPage,
      siteSettings,
      companyInfo,
      navigationLinks: composeNavigationLinks(siteSettings),
      translations,
      preview: !!preview,
    },
    revalidate: 5,
    notFound: false,
  };
};
